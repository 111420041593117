import React, { useEffect, useState } from 'react';
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Hero.module.sass";
import Image from "../../../components/Image";
import ScrollButton from "../../../components/ScrollButton";
import DownloadModal from "../../../components/ModalDownloader";

const Hero = ({ scrollToRef }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const titleElement = document.querySelector(`.${styles.title}`);
        if (titleElement) {
            const text = titleElement.textContent;
            const firstSpaceIndex = text.indexOf(' ');
            if (firstSpaceIndex !== -1) {
                const firstWord = text.slice(0, firstSpaceIndex);
                const remainingText = text.slice(firstSpaceIndex);
                titleElement.innerHTML = `<span class="${styles.firstWord}">${firstWord}</span>${remainingText}`;
            } else {
                titleElement.innerHTML = `<span class="${styles.firstWord}">${text}</span>`;
            }
        }
    }, []);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <div className={styles.hero}>
            <div className={cn("container", styles.container)}>
                <div className={styles.wrap}>
                    <h1 className={styles.title}>
                        Home for young
                        student athletes
                    </h1>
                    <div className={styles.text}>
                        Empower young athletes with our advanced coaching platform. Track performance, analyze match stats,
                        and elevate your game. Join now to support player development and discover new talent.
                    </div>
                    <div className={styles.btns}>
                        <button
                            className={cn("button", styles.request)}
                            onClick={openModal}
                        >
                            Download App
                        </button>
                    </div>
                </div>
                <ScrollButton
                    onScroll={() =>
                        scrollToRef.current.scrollIntoView({
                            behavior: "smooth",
                        })
                    }
                    className={styles.scroll}
                />
                <div className={styles.gallery}>
                    <div className={styles.preview}>
                        <Image
                            src="/images/content/hero.png"
                            srcDark="/images/content/hero.png"
                            alt="Watch"
                        />
                    </div>
                </div>
            </div>

            <DownloadModal isOpen={isModalOpen} onClose={closeModal} />
        </div>
    );
};

export default Hero;
