import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import styles from "./Header.module.sass";
import DropdownMenu from "./DropdownMenu";
import Icon from "../Icon";
import Image from "../Image";
import DownloadModal from "../ModalDownloader"; // Import the DownloadModal

const navLinks = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Pricing",
    url: "/pricing",
  },
  {
    title: "Contact",
    url: "/contact",
  },
];

const socials = [
  {
    title: "linkedin",
    size: "16",
    url: "https://www.linkedin.com/in/ahebbale",
  },
  {
    title: "instagram",
    size: "16",
    url: "https://www.instagram.com/sparkmysportz",
  },
];

const Headers = () => {
  const [visibleNav, setVisibleNav] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal

  const { pathname } = useLocation();

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <header className={styles.header}>
        <div className={cn("container", styles.container)}>
          <Link className={styles.logo} to="/">
            <Image
              className={styles.logo}
              src="/images/logo-dark.svg"
              srcDark="/images/header.png"
              alt="Spark My Sport"
            />
          </Link>
          <div className={cn(styles.wrap, { [styles.active]: visibleNav })}>
            <nav className={styles.nav}>
              {navLinks.map((x, index) =>
                x.content ? (
                  <DropdownMenu
                    className={styles.group}
                    item={x}
                    key={index}
                    setValue={setVisibleNav}
                  />
                ) : (
                  <NavLink
                    className={cn(styles.link, {
                      [styles.active]: pathname === x.url,
                    })}
                    to={x.url}
                    key={index}
                    onClick={() => setVisibleNav(false)}
                  >
                    {x.title}
                  </NavLink>
                )
              )}
            </nav>
            <div className={styles.details}>
              <div className={styles.socials}>
                {socials.map((x, index) => (
                  <a
                    className={styles.social}
                    href={x.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                  >
                    <Icon name={x.title} size={x.size} />
                  </a>
                ))}
              </div>
              <button
                onClick={openModal} // Open the modal on click
                className={styles.signup}
              >
                Download App
              </button>
            </div>
          </div>
          <button
            className={cn(styles.burger, {
              [styles.active]: visibleNav,
            })}
            onClick={() => setVisibleNav(!visibleNav)}
          ></button>
        </div>
      </header>

      {/* Include the DownloadModal component */}
      <DownloadModal isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default Headers;
