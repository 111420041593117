import React, { useRef } from "react";
// import styles from "./Home.module.sass";
import Hero from "./Hero";
import ValueProps from "../../components/ValueProps";
import Offer from "../../components/Offer";
import Features from "../../components/Features";

const Home = () => {
  const scrollToRef = useRef(null);

  return ( // make changes here for scroling pages
    <> 
      <Hero scrollToRef={scrollToRef} />  
      <ValueProps scrollToRef={scrollToRef}  className="section" />
      <Features className="section" />
      <Offer className="section" />
    </>
  );
};

export default Home;
