import React, { useRef, useEffect } from "react";
import styles from "./ModalDownloader.module.sass";

const DownloadModal = ({ isOpen, onClose }) => {
    const modalRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen, onClose]);

    const handleGooglePlayClick = () => {
        window.location.href = "https://play.google.com/store/apps/details?id=com.spark.mobile.app&hl=en_US";
        onClose();
    };

    const handleAppStoreClick = () => {
        window.location.href = "https://apps.apple.com/us/app/sparkmysport/id1547075908";
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className={styles.overlay}>
            <div ref={modalRef} className={styles.content}>
                <div className={styles.container}>
                    <h2 className={styles.heading}>Download App</h2>
                    <div className={styles.title}>Get the App Now</div>
                    <p className={styles.description}>
                        Download SparkMySport on Google Play or the Apple App
                        Store to elevate your basketball journey.
                    </p>
                    <div className={styles.footer}>
                        <div
                            onClick={handleGooglePlayClick}
                            className={styles.appButton}
                        >
                            <img
                                src="/images/playstore.svg"
                                alt="Google Play"
                            />
                        </div>
                        <div
                            onClick={handleAppStoreClick}
                            className={styles.appButton}
                        >
                            <img
                                src="/images/appstore.svg"
                                alt="App Store"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DownloadModal;