import React, { useState } from "react";
import cn from "classnames";
import styles from "./Features.module.sass";
import VideoCard from "./VideoCard";

const items = [
  {
    title: "Parents",
    header: "Unlock your young athlete's potential",
    content: "Our innovative platform showcases young athletes to college scouts and pro coaches, professionally displaying their achievements, skills, and stats to help them stand out in sports recruitment.",
    reverse: false,
    videoUrl: "https://skillteck-v2.s3.us-west-1.amazonaws.com/videos/1/library/vforparents.mp4",
    thumbnailUrl: "https://skillteck-v2.s3.us-west-1.amazonaws.com/videos/1/library/parentposter.jpg"
  },
  {
    title: "Players",
    header: "Elevate your game to next level",
    content: "With SparkMySport, strategize to win by comparing performance with peers, gaining actionable insights, and using our event grading and patented rating system to enhance your skills continuously.",
    reverse: true,
    videoUrl: "https://skilltech-v2-public.s3.us-west-1.amazonaws.com/website/NewPlayersVid.mp4",
    thumbnailUrl: "https://skillteck-v2.s3.us-west-1.amazonaws.com/videos/1/library/playerposter.jpg"
  }, 
  {
    title: "Coaches",
    header: "Streamline Your Coaching",
    content: "Our Platform simplifies your life by giving players direct access to their performance metrics. This means less time explaining basics and more time crafting winning strategies, streamlining your coaching process for team success.",
    reverse: false,
    videoUrl: "https://skilltech-v2-public.s3.us-west-1.amazonaws.com/website/NewCoachesVid.mp4",
    thumbnailUrl: "https://skillteck-v2.s3.us-west-1.amazonaws.com/videos/1/library/coachesposter.jpg"
  },
  {
    title: "Scouts",
    header: "Discover Elite Talent",
    content: "Our platform offers comprehensive tools to access profiles, stats, and highlights of athletes, streamlining your scouting process to find the right fit efficiently, saving time and enhancing recruitment strategy.",
    reverse: true,
    videoUrl: "https://skilltech-v2-public.s3.us-west-1.amazonaws.com/website/NewScoutsVid.mp4",
    thumbnailUrl: "https://skillteck-v2.s3.us-west-1.amazonaws.com/videos/1/library/scoutposter.jpg"
  },
];

const Trainer = ({ className }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div className={cn(styles.section, className)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <div className={cn("stage-small", styles.stage)}>
            How it Works
          </div>
          <div className={cn("h2", styles.title)}>
            Experience the
            SparkMySport advantage
          </div>
          <div className={styles.text}>
            Discover how SparkMySport simplifies player evaluation, enhances coaching strategies, and connects athletes with opportunities in the world of sports.
          </div>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <VideoCard item={x} key={index} reverse={index % 2 !== 0} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Trainer;
